@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;1,200;1,300&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  font-size: 1rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
	background-color: whitesmoke;
	margin-bottom: 2rem;
}

.navbar a {
	text-decoration: none;
	color: #000;
	font-size: 1.2rem;
	font-weight: 700;
}

.navbar a:hover {
	color: #000000;
}

form label {
	font-size: 1.2rem;
	font-weight: 400;
}

.App, html {
/*	background-color: #7ABAE0;*/
}

button a {
	text-decoration: none;
	color: white;
}

button a:hover {
	text-decoration: none;
	color: white;
}

.portal {
	margin-top: 2em;
}

.margin-bottom {
	margin-bottom: 2em;
}

.daily_schedule .tr, .move_schedule .tr, .perm_schedule .tr, .autoscale_schedule .tr, .asg_schedule .tr, .move_user_schedule .tr, .daily_check .tr, .dangling_server .container .tr, .stale_servers .container .tr, .zabbix_failed .container .tr, .clear_dns .container .tr, .rpRecId .container .tr, .rpStatus .container .tr, .lighthouse .tr, .analytics-callback .tr, .ovh_schedule .tr {
	border: 1px solid black;
}

.tr-edit {
	border-bottom: 1px solid black;
	border-left: 1px solid black;
}

.tr-edit a:hover, .tr-delete a:hover {
	text-decoration: none;
	color: white;
}

.tr-delete {
	border-bottom: 1px solid black;
	border-right: 1px solid black;
}

.dd-create {
	margin-top: 2em;
	margin-bottom: 2em;
}

.edit-btn a {
	color: white;
}

table {
	margin-top: 2em;
	max-width: 100%;
    width: 100% !important;
}

.table-gap {
	margin-bottom: 2em;
}

.chart {
	margin-top: 2em;
}

.row2 {
	margin-top: 2em;
}

.status-body {
	padding-top: 2em;
	margin-top: 2em;
}

nav li {
	list-style: none;
}

.help-button {
	float: right;
	text-decoration: none;
}

.help_component li {
	text-align: left;
	list-style: none;
}

.help_component p {
	text-align: left;
}

.help_component .card {
	margin-bottom: 2em;
}

/*.help_component .card img {
	height: 20vh;
}*/

.fixed {
	position: fixed;
}
/*
.chart-rna1, .chart-reu1, .chart-roc2, .chart-rna2 {
	border: 1px solid black;
}*/

/*------------------------------------------------------------LIGHTHOUSE------------------------------------------------------------*/

ul.lighthouse-hostnames {
	list-style: none;
	text-align: center;
	margin-top: 1em;
}

ul.lighthouse-hostnames li {
	padding: 1em;
	background-color: whitesmoke;
	border: 1px solid rgba(0, 0, 0, .2);
}

/*------------------------------------------------------------STRESS-TESTING------------------------------------------------------------*/

.link-primary:hover {
	cursor: pointer;
}

.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

/*------------------------------------------------------------RS-INBOX--------------------------------------------------------------------*/
.date {
	min-width: 100px;
}

/*div.analytics-callback p {
	text-align: left;
}
*/
